import './App.css';
import React, { useState, useEffect, useRef } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AppBar from '@mui/material/AppBar';

function App() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const productId = urlSearchParams.get('product_id');
  const endAtString = urlSearchParams.get('end_at');
  const extendMinutes = urlSearchParams.get('extend_minutes');

  const isLoaded = useRef(false);
  const endAtTimestamp = useRef(0);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (!isLoaded.current) {
      isLoaded.current = true;
      getData();
    }
    return () => {
      isLoaded.current = false;
      endAtTimestamp.current = 0;
    }
  }, []);

  const getData = () => {
    endAtTimestamp.current = Date.parse(`${endAtString}+08:00`) / 1000;

    if (typeof productId === 'string') {
      fetch(`https://auction-api.mozcardfinder.com/?product_id=${productId}`, { method: "POST" }).then(res => res.json()).then(arr => {
        while (arr.some(el => (endAtTimestamp.current > el.latest_price_created) && (el.latest_price_created > endAtTimestamp.current - extendMinutes * 60))) {
          endAtTimestamp.current += extendMinutes * 60;
        }
        setRows(arr.filter(el => isNaN(endAtTimestamp.current) ? true : endAtTimestamp.current > el.latest_price_created));
      });
    }
  }

  const getActiveOffers = () => {
    return rows.filter(row => ["C", "U"].includes(row.offer_state) === false);
  }

  const getHighestOffer = () => {
    let active_offers = getActiveOffers();
    let highest_price = active_offers.reduce((pv, cv) => (cv.latest_price > pv) ? cv.latest_price : pv, 0);
    return {
      username: active_offers.sort((a, b) => a.latest_price_created - b.latest_price_created).find(d => d.latest_price === highest_price)?.buyer_username ?? "無",
      price: highest_price
    };
  }

  const getHighestOfferInfo = () => {
    let result = getHighestOffer();
    return `${result.price} (${result.username})`;
  }

  const renderPriceCell = (offer) => {
    switch (offer.offer_state) {
      case "C": return <del style={{ color: "lightgray" }}>{offer.latest_price} (取消出價)</del>;
      case "U": return <span style={{ color: "lightgray" }}>{offer.latest_price} (更新出價)</span>;
      default: return (offer.latest_price);
    }
  }

  return (
    <>
      <AppBar
        position="static"
        sx={{ p: 2 }}
      >
        <div>目前最高出價: {getHighestOfferInfo()}</div>
        <div>拍賣將於{new Date(endAtTimestamp.current * 1000).toLocaleString()}完結</div>
      </AppBar >
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Username</TableCell>
              <TableCell>Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.sort((a, b) => b.latest_price_created - a.latest_price_created).map((row) => (
              <TableRow
                key={row.latest_price_created}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {new Date(row.latest_price_created * 1000).toLocaleString()}
                </TableCell>
                <TableCell>{row.buyer_username}</TableCell>
                <TableCell>{renderPriceCell(row)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default App;
